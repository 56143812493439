import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import {Paper, Divider, Typography, Card, CardActions, CardActionArea, CardContent, Button } from '@material-ui/core';
import { Link } from "react-router-dom"

import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(100),    
    },
    paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    }, 
    chip: {
        margin: theme.spacing(0.5)
    },
    section1: {
        margin: theme.spacing(3, 2)
    },
    section2: {
        margin: theme.spacing(2)
    },
    section3: {
        margin: theme.spacing(3, 1, 1)
    }
    },
  }));


  export default function Home() { 
    const classes = useStyles();
  
    return (
    <Layout>
      <div className={classes.root}>
        <Paper variant={'elevation'} square >
            <Card >
                <CardActionArea>
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        INTERVIEW QUESTIONS
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                  
                    </Typography>
                    </CardContent>
                </CardActionArea>

                <Divider variant="middle" />

                <CardActions>
                    <Button size="small" color="primary"><Link className="links" to="/react">react</Link></Button>
                    <Button size="small" color="primary">
                    redux
                    </Button>
                    <Button size="small" color="primary">
                    react router
                    </Button>
  
                </CardActions>
                <Divider variant="middle" />
                <CardActions>
                    <Button size="small" color="primary">
                    React Testing
                    </Button>
                    <Button size="small" color="primary">
                    React Native
                    </Button>
                    <Button size="small" color="primary">
                    Miscellaneous
                    </Button>
                </CardActions>
                <Divider variant="middle" />
                <CardActions>
                    <Button size="small" color="primary">
                    React Internationalization
                    </Button>
                </CardActions>
                <Divider variant="middle" />
                <CardActions>
                    <Button size="small" color="primary">
                    React supported libraries and Integration
                    </Button>
                </CardActions>
            </Card>
        </Paper>



      </div>
      </Layout>
    );
  }