import React from "react"
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, AppBar} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: 'white'
    },
  }));


  export default function Header() {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar><Link className="links" to="/">
            <Typography variant="h6" className={classes.title}>
              Web1School.com
            </Typography></Link>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
