import React from 'react';
import ReactDOM from 'react-dom'
import { createBrowserHistory } from "history";
import {HashRouter as Router,  Route, Switch, Redirect, BrowserRouter } from "react-router-dom"

import './index.css';
import "typeface-raleway"
import Home from "./pages/home"
import NotFound from "./pages/notfound"
import Questions from "./pages/Questions"

const hist = createBrowserHistory();
ReactDOM.render(
    <BrowserRouter history={hist}>
         <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path={["/react", "/router", "/react-native", "/redux", "/react-testing", "/react-int", "/react-library"]} component={Questions} />
        </Switch>
    </BrowserRouter>, 
    document.getElementById('root')
);
