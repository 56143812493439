import React, {Component} from "react"
import ReactMarkdown from 'react-markdown'
import { createBrowserHistory } from "history";

import SynHi from "../components/SynHi"
import Layout from "../components/layout"

import "./pages.css"

import QRedux from './content/REDUX.md';
import QReact from './content/REACT.md';

const history = createBrowserHistory()

// const githubUrl ='https://raw.githubusercontent.com/Web1School-com/en.web1school.com/master/store/02-react/interview/REDUX.md';
class Questions extends Component {
    state = {
      post: null,
    }
    componentDidMount() {
 
   //   let { id } = useParams();
   // console.log(id, 'id');
   console.log(history.location.pathname);
      fetch(QRedux)
        .then(res => res.text())
        .then(post => this.setState((state) => ({ ...state, post })))
        .catch((err) => console.error(err));
    }
    renderers = {
      code: ({ language, value }) => {
        return <SynHi language={language}>{value}</SynHi>
      }
    }
    render() {
      const { post } = this.state;
      return (
        <Layout>
        <div >
            <ReactMarkdown 
            source={post} 
            escapeHtml={true}  
            renderers={this.renderers} 
            />
                   </div>
        </Layout>
      );
    }
  }
  
  export default Questions;